import { DateTime, Settings } from 'luxon';
import { DurationLikeObject } from 'luxon/src/duration';
import { TimeZone } from './enum';

export type DayConfig = number | string | Date | DateTime;
export type DurationObject = DurationLikeObject;
const rules = new Intl.PluralRules('en-US');

Settings.throwOnInvalid = true;

// we wrap this to make sure no part of the app forgets about the extends above
export function datetime(date: DayConfig, timezone: TimeZone): DateTime;
export function datetime(date: DayConfig | undefined, timezone: TimeZone): DateTime | undefined;
export function datetime(date: DayConfig | undefined, timezone: TimeZone): DateTime | undefined {
  if (date === undefined) {
    return undefined;
  }

  if (date instanceof DateTime) {
    return date.set({ millisecond: 0 });
  }

  let dt;

  if (date instanceof Date) {
    dt = DateTime.fromJSDate(date, { zone: timezone }).set({ millisecond: 0 });
  }

  if (typeof date === 'number') {
    if (date < 9999999999) {
      dt = DateTime.fromSeconds(date, { zone: timezone }).set({ millisecond: 0 });
    } else {
      dt = DateTime.fromMillis(date, { zone: timezone }).set({ millisecond: 0 });
    }
  }

  if (typeof date === 'string') {
    if (date.length === 19 && date.includes(':')) {
      dt = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: timezone }).set({ millisecond: 0 });
    } else if (date.length === 10) {
      dt = DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: timezone }).set({ millisecond: 0 });
    } else {
      dt = DateTime.fromISO(date, { zone: timezone }).set({ millisecond: 0 });
    }
  }

  if (!dt || !dt.isValid) {
    throw new Error(`Invalid date format: ${date}`);
  }

  return dt;
}

export function datetime_replace_time(date: DateTime, time: TTime24) {
  const [hours, minutes] = time.split(':').map((n: string) => parseInt(n, 10));

  return date.set({ hour: hours, minute: minutes });
}

export function ordinal(date: DateTime) {
  const ordinal = rules.select(date.day);

  switch (ordinal) {
    case 'one':
      return 'st';
    case 'two':
      return 'nd';
    case 'few':
      return 'rd';
    default:
      return 'th';
  }
}

export function formalDay(day: DateTime, short = false) {
  return `${day.toFormat(short ? 'MMM d' : 'MMMM d')}${ordinal(day)}, ${day.toFormat('yyyy')}`;
}

export function formalTime(time: DateTime, seconds = false) {
  const timeString = time.toFormat(`h:mm${seconds ? ':ss' : ''} a ZZZZ`);

  return `${formalDay(time)} @ ${timeString}`;
}
